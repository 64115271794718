import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Head from "../components/header/head"

type NotFoundProps = {}

const NotFound = (props: NotFoundProps) => {
  return (
    <Layout headerColor="black" headerPageName="404" headerSubTitle="" headerTitle="404">
      <Head title="404" description="Page not found" />
      <div className="flex flex-col items-center text-gray-700">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-40 w-40"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
          />
        </svg>
        <h1 className="mt-8">La page que vous cherchez n'existe pas</h1>
        <p>
          Revenez sur la page{" "}
          <Link to="/" className="hover:text-blue-700 text-blue-500">
            la page principale
          </Link>{" "}
          ou{" "}
          <Link to="/recherche" className="hover:text-blue-700 text-blue-500">
            {" "}
            faites une recherche
          </Link>
        </p>
      </div>
    </Layout>
  )
}
export default NotFound
